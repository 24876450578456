


















































































































import { Component, Vue } from "vue-property-decorator";
import { mdiKey, mdiArrowLeft, mdiEmail } from "@mdi/js";
import { mask } from "vue-the-mask";
import storage from "@/services/web-storage";
import Authentication from "@/services/Authentication";
import { Rules, Focusable, IconsType } from "@/types";

// eslint-disable-next-line no-control-regex
const emailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

@Component({
  components: {
    LangSelect: () => import("@/components/Menu/LangSelect.vue")
  },
  directives: {
    mask
  }
})
export default class VAuth extends Vue {
  private isEmailValid = false;
  private isCodeValid = false;
  private gettingCode = false;
  private signingIn = false;
  private firstStep = true;
  private code = "";
  private email = "";
  private saveEmail = true;
  private emailRules: Rules = [
    value => !!value || this.$t("pages.auth.rules.email-required"),
    value => emailRegex.test(value) || this.$t("pages.auth.rules.email-format")
  ];
  private codeRules: Rules = [
    value => !!value || this.$t("pages.auth.rules.code-required"),
    value => value.length === 6 || this.$t("pages.auth.rules.code-length")
  ];
  private icons: IconsType = {
    email: mdiEmail,
    key: mdiKey,
    arrowLeft: mdiArrowLeft
  };
  public onGetCode(): void {
    if (!this.isEmailValid) return;
    this.gettingCode = true;
    setTimeout(async () => {
      if (await Authentication.code(this.email)) {
        this.firstStep = false;
        this.focus("code");
        if (this.saveEmail) {
          this.savePhone();
        } else {
          this.deletePhone();
        }
        await this.$store.dispatch("SnackbarModule/reset");
      } else {
        await this.$store.dispatch(
          "SnackbarModule/set",
          this.$t("snackbar.message-auth-email")
        );
      }
      this.gettingCode = false;
    }, 0);
  }
  public onSignIn(): void {
    if (!this.isCodeValid || !this.isEmailValid) return;
    this.signingIn = true;
    setTimeout(async () => {
      const res = await Authentication.login({
        email: this.email,
        code: this.code
      });
      if (res) {
        await this.$router.push("/");
        await this.$store.dispatch("SnackbarModule/reset");
      } else {
        await this.$store.dispatch(
          "SnackbarModule/set",
          this.$t("snackbar.message-auth-code")
        );
      }
      this.signingIn = false;
    });
  }
  public focus(ref: string): void {
    setTimeout(() => {
      (this.$refs[ref] as Focusable).focus();
    }, 0);
  }
  public reset(): void {
    this.firstStep = true;
  }
  public savePhone(): void {
    storage.setEmail(this.email);
  }
  public deletePhone(): void {
    storage.removeEmail();
  }
  mounted() {
    if (storage.getEmail() && storage.getEmail().length > 0)
      this.email = storage.getEmail();
  }
}
